import type { Action, ThunkAction } from "@reduxjs/toolkit"
import { combineSlices, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { appDataSlice } from "../features/common/commonSlices/AppDetailSlice"
import { ApiInit } from "@services/customBaseQuery"
import { authSlice } from "@features/userAuth/UserAuthSlice"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import { userSessionSlice } from "@features/common/commonSlices/UserSessionDetailSlice"
import { createCampaignSlice } from "@features/createNewCampaign/CreateNewCampaignSlice"
import { partialThemeConfigSlice } from "../features/createNewCampaign/giftShopConfig/giftShopHome/partialThemeConfigSlice"
import { toastSlice } from "../features/common/Toast/toastSlice"
import { profileDataSlice } from "../features/home/HomeSlice"
import { deliverCampaignDataSlice } from "@features/deliverCampaigns/DeliverCampaignsSlice"
import { bulkPaymentSlice } from "@features/campaignPayment/bulkPaymentSlice"
import { corporateSelectionSlice } from "@features/corporateSelection/CorporatesSelectionSlice"

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const rootReducer = combineSlices(
  {
    // Add the generated reducer as a specific top-level slice
    [ApiInit.reducerPath]: ApiInit.reducer,
  },
  partialThemeConfigSlice,
  authSlice,
  userSessionSlice,
  appDataSlice,
  createCampaignSlice,
  toastSlice,
  profileDataSlice,
  deliverCampaignDataSlice,
  bulkPaymentSlice,
  corporateSelectionSlice,
)

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "userSessionSlice",
    "createCampaignSlice",
    "profileDataSlice",
    "deliverCampaignDataSlice",
    "corporateSelectionSlice",
  ], // only persist these keys
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
// Add the generated reducer as a specific top-level slice
// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(ApiInit.middleware),
  })
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch)
  return store
}

export const store = makeStore()
export const persistor = persistStore(store)

// Infer the type of `store`
export type AppStore = typeof store
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"]
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>
