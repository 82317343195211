import { createAppSlice } from "@redux/createAppSlice"
import { type PayloadAction } from "@reduxjs/toolkit"
import {
  DEFAULT_CREATE_CAMPAIGN_DATA,
  FAQ_DATA,
  STATIC_BLOCK_DATA,
} from "@constants/app.data"
import theme from "@theme/theme"
interface bodyDataInterface {
  [key: string]: string
}

export interface CampaignDetails {
  id: number | null
  happyCard: string
  campaignName: string
  budget: string
  startDate: string
  invoicing_type: string
  endDate: string
  status?: string
  timezone?: string
  isExpired?: boolean
}

export interface SiteImages {
  bannerEnImageUrl: string
  bannerArImageUrl: string
  staticBlockEnImageUrl: string
  staticBlockArImageUrl: string
}
export interface SiteConfigDetails {
  redemptionType: "mobile_app" | "web" | "both"
  validationType: "email_and_phone" | "email" | "phone"
  slug: string
  corporate_slug: string
  primaryColor: string
  secondaryColor: string
  title: string
  title_ar: string
  faq: string
  faqAr: string
  staticBlock: string
  staticBlockAr: string
  siteImages?: SiteImages
}
export interface CreateCampaignSliceState {
  campaignDetails: CampaignDetails
  siteConfigDetails: SiteConfigDetails
  brandDetails: any
  uploadType: string
  cachedCampaignConfig: CachedCampaignConfig
}

export interface CachedCampaignConfig {
  is_editable: boolean
  slug: string
  gs_config_data: SiteConfigDetails
}

export const configInitialData = {
  redemptionType: DEFAULT_CREATE_CAMPAIGN_DATA.data.redemption_type as
    | "mobile_app"
    | "web"
    | "both",
  validationType: DEFAULT_CREATE_CAMPAIGN_DATA.data.validation_type as
    | "email_and_phone"
    | "email"
    | "phone",
  slug: "",
  corporate_slug: "",
  primaryColor: theme.palette.primary.main,
  secondaryColor: "#fff",
  title: DEFAULT_CREATE_CAMPAIGN_DATA.data.title.en,
  title_ar: DEFAULT_CREATE_CAMPAIGN_DATA.data.title.ar,
  faq: FAQ_DATA.data.en,
  faqAr: FAQ_DATA.data.ar,
  staticBlock: STATIC_BLOCK_DATA.data.en,
  staticBlockAr: STATIC_BLOCK_DATA.data.ar,
}

const initialState: CreateCampaignSliceState = {
  campaignDetails: {
    id: null,
    happyCard: "",
    campaignName: "",
    invoicing_type: "",
    budget: "",
    startDate: "",
    endDate: "",
    status: "",
    timezone: "",
  },
  siteConfigDetails: configInitialData,
  brandDetails: null,
  uploadType: "",
  cachedCampaignConfig: {
    is_editable: true,
    slug: "",
    gs_config_data: configInitialData,
  },
}

export const createCampaignSlice = createAppSlice({
  name: "createCampaignSlice",
  initialState,
  reducers: create => ({
    setUploadType: create.reducer((state, action: PayloadAction<string>) => {
      state.uploadType = action.payload
    }),
    updateCampaignDetails: create.reducer(
      (state, action: PayloadAction<CampaignDetails>) => {
        state.campaignDetails = action.payload
      },
    ),
    updateSiteConfigDetails: create.reducer(
      (state, action: PayloadAction<SiteConfigDetails>) => {
        state.siteConfigDetails = action.payload
      },
    ),
    updateCachedCampaignConfig: create.reducer(
      (state, action: PayloadAction<CachedCampaignConfig>) => {
        state.cachedCampaignConfig = action.payload
      },
    ),
    changeRedemptionType: create.reducer(
      (state, action: PayloadAction<"mobile_app" | "web" | "both">) => {
        state.siteConfigDetails.redemptionType = action.payload
      },
    ),
    changeValidationType: create.reducer(
      (state, action: PayloadAction<"email_and_phone" | "email" | "phone">) => {
        state.siteConfigDetails.validationType = action.payload
      },
    ),
    changeFaqDataAr: create.reducer((state, action: PayloadAction<string>) => {
      state.siteConfigDetails.faqAr = action.payload
    }),
    changeFaqDataEn: create.reducer((state, action: PayloadAction<string>) => {
      state.siteConfigDetails.faq = action.payload
    }),
    changeCampaignSlug: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.siteConfigDetails.slug = action.payload
      },
    ),
    changeTitle: create.reducer((state, action: PayloadAction<string>) => {
      state.siteConfigDetails.title = action.payload
    }),
    changeTitleAr: create.reducer((state, action: PayloadAction<string>) => {
      state.siteConfigDetails.title_ar = action.payload
    }),
    changeStaticBlockEn: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.siteConfigDetails.staticBlock = action.payload
      },
    ),
    changeStaticBlockAr: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.siteConfigDetails.staticBlockAr = action.payload
      },
    ),
    setPrimaryColor: create.reducer((state, action: PayloadAction<string>) => {
      state.siteConfigDetails.primaryColor = action.payload
    }),
    setSecondaryColor: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.siteConfigDetails.secondaryColor = action.payload
      },
    ),
    updateBrandDetails: create.reducer((state, action: PayloadAction<any>) => {
      state.brandDetails = action.payload
    }),

    setSitConfigDataToDefault: create.reducer(
      (state, action: PayloadAction<{ isRedemptionTypeMobile: boolean }>) => {
        if (action.payload.isRedemptionTypeMobile) {
          state.siteConfigDetails = {
            ...initialState.siteConfigDetails,
            redemptionType: "mobile_app",
          }
        } else {
          state.siteConfigDetails = {
            ...initialState.siteConfigDetails,
          }
        }
      },
    ),

    resetCampaignDetails: () => initialState,
  }),
  selectors: {
    selectUploadType: appData => appData.uploadType,
    selectCampaignDetails: appData => appData.campaignDetails,
    selectRedemptionType: appData => appData.siteConfigDetails.redemptionType,
    selectValidationType: appData => appData.siteConfigDetails.validationType,
    selectFaqData: appData => {
      return {
        faqDataAr: appData.siteConfigDetails.faqAr,
        faqDataEn: appData.siteConfigDetails.faq,
      }
    },
    selectStaticBlockData: appData => {
      return {
        staticBlockDataAr: appData.siteConfigDetails.staticBlockAr,
        staticBlockDataEn: appData.siteConfigDetails.staticBlock,
      }
    },
    selectSiteConfigDetails: appData => appData.siteConfigDetails,
    selectCreateCampaignBodyData: appData => {
      let bodyData: bodyDataInterface
      if (appData.siteConfigDetails.redemptionType === "mobile_app") {
        bodyData = {
          generic_brand: appData.campaignDetails.happyCard,
          campaign_name: appData.campaignDetails.campaignName,
          budget: appData.campaignDetails.budget,
          start_date: appData.campaignDetails.startDate,
          end_date: appData.campaignDetails.endDate,
          invoicing_type: appData.campaignDetails.invoicing_type,
          redemption_type: appData.siteConfigDetails.redemptionType,
        }
      } else {
        bodyData = {
          generic_brand: appData.campaignDetails.happyCard,
          campaign_name: appData.campaignDetails.campaignName,
          budget: appData.campaignDetails.budget,
          start_date: appData.campaignDetails.startDate,
          end_date: appData.campaignDetails.endDate,
          redemption_type: appData.siteConfigDetails.redemptionType,
          validation_type: appData.siteConfigDetails.validationType,
          slug: appData.siteConfigDetails.slug,
          invoicing_type: appData.campaignDetails.invoicing_type,
          primary_color: appData.siteConfigDetails.primaryColor,
          secondary_color: appData.siteConfigDetails.secondaryColor,
          title: appData.siteConfigDetails.title,
          title_ar: appData.siteConfigDetails.title_ar,
          faq: appData.siteConfigDetails?.faq?.replace(/\n\s*/g, ""),
          faq_ar: appData.siteConfigDetails?.faqAr?.replace(/\n\s*/g, ""),
          static_block: appData.siteConfigDetails.staticBlock.replace(
            /\n\s*/g,
            "",
          ),
          static_block_ar: appData.siteConfigDetails.staticBlockAr.replace(
            /\n\s*/g,
            "",
          ),
        }
      }
      return bodyData
    },
    selectBrandDetails: appData => appData.brandDetails,
    selectCachedConfigDetails: appData => appData.cachedCampaignConfig,
  },
})

export const {
  setUploadType,
  updateCampaignDetails,
  updateSiteConfigDetails,
  changeRedemptionType,
  changeValidationType,
  changeFaqDataEn,
  changeFaqDataAr,
  changeCampaignSlug,
  changeStaticBlockAr,
  changeStaticBlockEn,
  changeTitle,
  changeTitleAr,
  setPrimaryColor,
  setSecondaryColor,
  resetCampaignDetails,
  updateBrandDetails,
  setSitConfigDataToDefault,
  updateCachedCampaignConfig,
} = createCampaignSlice.actions

export const {
  selectRedemptionType,
  selectValidationType,
  selectFaqData,
  selectSiteConfigDetails,
  selectCreateCampaignBodyData,
  selectBrandDetails,
  selectCampaignDetails,
  selectStaticBlockData,
  selectUploadType,
  selectCachedConfigDetails,
} = createCampaignSlice.selectors
