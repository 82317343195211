import {
  AllocatedBudgetIcon,
  AvailableBalanceIcon,
  TotalBalanceIcon,
} from "@features/common/SvgIcons"
import { selectBasicDetails } from "@features/home/HomeSlice"
import { Tooltip, Typography } from "@mui/material"
import { useAppSelector } from "@redux/hooks"
import { convertToCurrency } from "@utils/convertToCurrency"
import type { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

function getTooltipText(type: string, t: TFunction) {
  switch (type) {
    case "totalBalance":
      return t("totalBalanceCalculation")
    case "availableBalance":
      return t("availableBalanceCalculation")
    case "allocatedCampaignBudget":
      return t("allocatedBudgetCalculation")
    default:
      return ""
  }
}

function getIcon(type: string) {
  switch (type) {
    case "totalBalance":
      return { icon: <TotalBalanceIcon />, bgColor: "#FFF5D9" }
    case "availableBalance":
      return { icon: <AvailableBalanceIcon />, bgColor: "#0071FF14" }
    case "allocatedCampaignBudget":
      return { icon: <AllocatedBudgetIcon />, bgColor: "#DCFAF8" }
    default:
      return { icon: "", bgColor: "" }
  }
}

const BalanceCard = ({ value, label }: { value: string; label: string }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const toolTipLabel = getTooltipText(label, t)

  const { icon, bgColor } = getIcon(label)

  const basicDetails = useAppSelector(selectBasicDetails)

  const currencyCode = basicDetails?.currencyCode

  return (
    <Tooltip title={toolTipLabel} placement="bottom">
      <div
        className={`min-width-330 bg-cl-white brdr-secondary brdr-decimal-5 brdr-cl-gallery box-shadow-card display-flex align-items-center px16 py15`}
      >
        <div
          style={{ backgroundColor: bgColor }}
          className={`display-flex align-items-center justify-content-center width70 height70 brdr-full bg-cl-aqua-haze ${language === "ar" ? "ml24" : "mr24"}`}
        >
          {icon}
        </div>

        <div
          className={`${
            language === "ar" ? "" : "gap5"
          } display-flex flex-direction-column gap5`}
        >
          <h6 className={"fs12 cl-mine-shaft opacity7"}>{t(label)}</h6>
          <Typography color={"secondary"} variant="h4">
            {convertToCurrency(value, currencyCode)}
          </Typography>
        </div>
      </div>
    </Tooltip>
  )
}

export default BalanceCard
