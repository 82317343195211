import PAGE_URLS from "@constants/pageUrls"
import { DeleteIconUpdated } from "@features/common/SvgIcons"
import { selectBasicDetails } from "@features/home/HomeSlice"
import { useAppSelector } from "@redux/hooks"
import { captureException } from "@sentry/react"
import { capitalize } from "@utils/capitalize"
import { convertToCurrency } from "@utils/convertToCurrency"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import CampaignStatusChip from "../campaignStatusChip/CampaignStatusChip"
import CampaignDeleteModal from "../Modal/CampaignDeleteModal"
import { useCampaignDeleteMutation } from "../MyCampaignApi"
import type { IMyCampaign } from "../types/myCampaignApiType"

interface Props {
  details: IMyCampaign["campaigns"][0]
  refetchCampaign: () => void
}

const CampaignCard: React.FC<Props> = ({ details, refetchCampaign }) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const basicDetails = useAppSelector(selectBasicDetails)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()

  const [campaignDelete, { isLoading, isError, error }] =
    useCampaignDeleteMutation()

  const budget = Number(details?.budget?.split(" ")?.[1]) ?? ""
  const usedBudget =
    Number(details?.campaign_budget_used?.split(" ")?.[1]) ?? ""

  const isGiftActivation = details?.invoicing_type === "gift_activation"

  const deleteHandler = async () => {
    let res: any
    try {
      res = await campaignDelete({
        url: details?.delete_url as string,
      })
    } catch (err) {
      captureException(err, {
        tags: { location: "Delete campaign Api", component: "CampaignCard" },
        extra: { url: details?.delete_url },
      })
    }
    if (res?.data) {
      setDeleteModal(false)
      refetchCampaign()
    }
  }

  useEffect(() => {
    if (isError) {
      captureException(error, {
        tags: { location: "Delete campaign Api", component: "CampaignCard" },
        extra: { url: details?.delete_url },
      })
    }
  }, [isError])

  return (
    <div>
      <CampaignDeleteModal
        isLoading={isLoading}
        open={deleteModal}
        setOpen={setDeleteModal}
        submitFunction={deleteHandler}
      />
      <div
        className={
          "w-100 bg-cl-white brdr-secondary brdr-decimal-5 brdr-cl-gallery box-shadow-card display-flex flex-direction-column"
        }
      >
        <div
          onClick={() =>
            navigate(
              `/${PAGE_URLS.BASE_PATH}/${language}/${PAGE_URLS.MY_CAMPAIGNS}/${details?.id}`,
            )
          }
          className={`cursor-pointer display-flex flex-direction-column py23 ${language === "en" ? "min-h-200" : "min-h-245"} px16`}
        >
          <div className="display-flex align-items-center flex-wrap-wrap gap10 justify-content-between">
            <h3 className={"fs16 fw600"}>{details?.name}</h3>
            {/* {usedBudget !== "0" && (
              <h2 className={"fs20 align-self-end fw600"}>
                {convertToCurrency(budget, basicDetails?.currencyCode)}
              </h2>
            )} */}
            <h4 className="cl-pale-sky fs12">{t("budget")}</h4>
          </div>
          <div
            className={
              "display-flex justify-content-between gap10 mb40 pt10 align-items-start"
            }
          >
            {details?.status !== "created" && (
              <h6 className={"fs14 fw500 max-w-220 cl-pale-sky"}>
                {details?.upload_type === "email_sms"
                  ? t("deliverViaEmail&Sms")
                  : t("bulkDelivery")}
              </h6>
            )}
            <div
              className={`display-flex ${language === "en" ? "margin-left-auto" : "margin-right-auto"}  justify-self-end flex-direction-column gap10`}
            >
              <h2 className={"fs20 align-self-end fw600"}>
                {convertToCurrency(budget, basicDetails?.currencyCode)}
              </h2>

              {/* {usedBudget !== "0" ? (
                <p className="fs14 fw500 align-self-end cl-pale-sky">
                  {t("used")} :{" "}
                  {convertToCurrency(usedBudget, basicDetails?.currencyCode)}
                </p>
              ) : (
                ""
              )} */}
            </div>
          </div>
          <h6 className={"fs12 fw500 cl-pale-sky margin-top-auto mb10"}>
            {t("endDate")}
          </h6>
          <h6 className={"fs14 fw500"}>
            {details?.end_date} {basicDetails?.countryCode}
          </h6>
        </div>
        <div
          className={`p16 w-100 h-100 min-h-68 brdr-top-decimal-5 brdr-bottom-1 brdr-bottom-secondary brdr-cl-gallery display-flex justify-content-between align-items-center`}
        >
          <div className={"display-flex gap10 w-100 flex-wrap-wrap"}>
            {details?.status === "created" && (
              <CampaignStatusChip theme="black" label={t("created")} />
            )}
            {details?.status === "downloaded" && (
              <>
                <CampaignStatusChip theme="black" label={t("created")} />
                <CampaignStatusChip theme="green" label={t("downloaded")} />
              </>
            )}
            {details?.status === "delivered" && (
              <>
                <CampaignStatusChip theme="black" label={t("created")} />
                <CampaignStatusChip theme="green" label={t("delivered")} />
              </>
            )}
            {details?.is_cancelled ? (
              <CampaignStatusChip theme="red" label={t("cancelled")} />
            ) : details?.is_expired ? (
              <CampaignStatusChip theme="red" label={t("expired")} />
            ) : (
              ""
            )}
            {details?.is_delete_enabled && (
              <button
                onClick={() => {
                  setDeleteModal(true)
                }}
                className="outline-none cursor-pointer bg-cl-white margin-left-auto"
              >
                <DeleteIconUpdated />
              </button>
            )}
          </div>
        </div>

        <div className="py18 px16 display-flex align-items-center justify-content-between">
          <h4 className="fs12">
            <span className="cl-mine-shaft">{t("used")}</span> :{" "}
            <span className="fw600 cl-mine-shaft">
              {convertToCurrency(usedBudget, basicDetails?.currencyCode)}
            </span>
          </h4>

          {isGiftActivation && (
            <h4 className="fs12">
              <span className="cl-mine-shaft">
                {capitalize(t("activated"))}
              </span>{" "}
              :{" "}
              <span className="fw600 cl-mine-shaft">
                {convertToCurrency(
                  details?.activated_amount,
                  basicDetails?.currencyCode,
                )}
              </span>
            </h4>
          )}
        </div>
      </div>
    </div>
  )
}

export default CampaignCard
