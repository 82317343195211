import PageLoader from "@features/common/pageLoader/PageLoader"
import {
  selectAccountBalance,
  selectBasicDetails,
  updateCampaignCount,
} from "@features/home/HomeSlice"
import NewCampaignButton from "@features/home/newCampaignButton/NewCampaignButton"
import CampaignCard from "@features/myCampaign/CampaignCard/CampaignCard"
import CampaignFilter from "@features/myCampaign/CampaignFilter/CampaignFilter"
import { useMyCampaignListMutation } from "@features/myCampaign/MyCampaignApi"
import BalanceCard from "@features/myCampaign/balanceCard/BalanceCard"
import type { IMyCampaign } from "@features/myCampaign/types/myCampaignApiType"
import { CircularProgress } from "@mui/material"
import { useAppDispatch, useAppSelector } from "@redux/hooks"
import { captureException } from "@sentry/react"
import useDebounce from "@utils/customHooks/useDebounce"
import useSmoothScrollToTop from "@utils/customHooks/useSmoothScrollToTop"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import styles from "./MyCampaign.module.scss"

interface FilterProps {
  search: string
  filters: { id: number; label: string; name: string; checked: boolean }[]
}

function convertToNumber(balance: string) {
  if (balance) {
    const convertedBalance = balance?.split(" ")?.[1]
    return convertedBalance
  }
  return "0"
}

const MyCampaigns = () => {
  useSmoothScrollToTop()
  const [campaignData, setCampaignData] = useState<IMyCampaign["campaigns"]>([])
  const [filter, setFilter] = useState<FilterProps>({
    search: "",
    filters: [
      { id: 1, label: "Created", name: "created", checked: false },
      { id: 2, label: "Expired", name: "expired", checked: false },
      { id: 3, label: "Delivered", name: "delivered", checked: false },
      { id: 4, label: "Downloaded", name: "downloaded", checked: false },
      { id: 5, label: "Cancelled", name: "cancelled", checked: false },
    ],
  })

  const debouncedSearch = useDebounce<string>(filter.search, 1000)

  //true if campaign is searched or any filter is enabled
  const emptyFilter = !filter.search && filter.filters.every(el => !el.checked)

  const basicDetails = useAppSelector(selectBasicDetails)
  const [next, setNext] = useState("")
  const [total, setTotal] = useState(0)
  const [refetch, setRefetch] = useState(false)
  const dispatch = useAppDispatch()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const [noResults, setNoResults] = useState(false)
  const [myCampaignApi, { data, isLoading, isSuccess, isError, error }] =
    useMyCampaignListMutation()

  const getQueryParams = () => {
    const { search, filters } = filter

    const query: Record<string, string> = {}
    if (search) {
      query.q = search
    }

    const selectedFilters = filters
      .filter(filter => filter.checked)
      .map(filter => filter.name)

    if (selectedFilters.length > 0) {
      query["status__in"] = selectedFilters.join(",")
    }

    const queryString = new URLSearchParams(query).toString()
    return queryString
  }

  useEffect(() => {
    const getAllCampaigns = async () => {
      setLoader(true)
      let res: any
      let query = getQueryParams()
      try {
        res = await myCampaignApi({
          lng: language,
          query: query,
        })
      } catch (err) {
        captureException(err, {
          tags: { location: "My Campaign Api" },
        })
        setLoader(false)
      }
      if (query.length > 0 && res?.data?.total_campaigns_count === 0) {
        setNoResults(true)
      }
      if (res?.data?.total_campaigns_count !== 0) {
        setCampaignData([...(res?.data?.campaigns as IMyCampaign["campaigns"])])
        setNext(res?.data?.next)
        setTotal(res?.data?.total_count)
        setNoResults(false)
      }
      setLoader(false)
    }
    getAllCampaigns()
  }, [language, myCampaignApi, refetch, debouncedSearch, filter.filters])

  useEffect(() => {
    if (isSuccess) {
      const activeCount = data?.active_campaigns_count
      const totalCount = data?.total_campaigns_count
      dispatch(
        updateCampaignCount({
          active_campaigns: activeCount,
          total_campaigns: totalCount,
        }),
      )
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      captureException(error, {
        tags: { location: "My Campaign Api" },
      })
    }
  }, [isError])

  const freeBalance = convertToNumber(data?.free_balance as string)

  const totalBalance = convertToNumber(data?.available_balance as string)

  const reservedBudget = convertToNumber(data?.reserved_budget as string)

  const [showLoader, setLoader] = useState(false)
  const fetchMoreData = useCallback(async () => {
    let query = getQueryParams()

    let res: any = await myCampaignApi({
      lng: language,
      url: next,
      query: query,
    })
    setNext(res?.data?.next)
    setCampaignData(prev => [
      ...prev,
      ...(res?.data?.campaigns as IMyCampaign["campaigns"]),
    ])
  }, [language, myCampaignApi, next])

  useEffect(() => {
    const root = document.getElementById("scrollableTarget")
    if (
      !isLoading &&
      next &&
      total !== 0 &&
      root!?.scrollHeight <= root!?.clientHeight
    ) {
      fetchMoreData()
    }
  }, [total, fetchMoreData, isLoading, next])

  if (isLoading && next?.length === 0) {
    return <PageLoader />
  }

  if (noResults) {
    return (
      <div className="bg-cl-white">
        <BalanceTiles />
        <div className="display-flex align-items-center justify-content-between py0 px24">
          <h3 className="fs14 fw600 cl-pale-sky min-width-160 shrink-0 shrink-0">
            {t("createdCampaigns")}
          </h3>
          <div className="height-decimal-5 flex-grow bg-cl-gallery ml10 w-100"></div>
        </div>
        <CampaignFilter
          filter={filter}
          setFilter={setFilter}
          isLoading={isLoading}
        />

        <div className="width-100 h-100vh bg-cl-white overflow-hidden py140">
          {showLoader ? (
            <div className="display-flex flex-grow w-100 align-items-center justify-content-center py16 px0">
              <CircularProgress color="primary" />
            </div>
          ) : (
            <div className="display-flex overflow-hidden justify-content-center flex-direction-column gap41 align-items-center">
              <img
                src={"/corporate/assets/no-campaign.svg"}
                alt="no campaign"
              />
              <p className="cl-pale-sky fs16">{t("noSearchResults")}</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  if (data?.total_campaigns_count === 0 && data) {
    return (
      <div className="bg-cl-white">
        <BalanceTiles />
        <div className="width-100 h-100vh bg-cl-white overflow-hidden py140">
          <div className="display-flex overflow-hidden justify-content-center flex-direction-column gap41 align-items-center">
            <img src={"/corporate/assets/no-campaign.svg"} alt="no campaign" />
            <div className="text-align-center">
              <h2 className="fs24 fw600 cl-mine-cl-mine-shaft mb20">
                {t("thereAreNoCampaigns")}
              </h2>
              <NewCampaignButton />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id={"scrollableTarget"} className={styles["my-campaigns"]}>
      <div style={{ minHeight: "158px" }}>
        <BalanceTiles />
      </div>
      {campaignData?.length && total ? (
        <>
          <div className="display-flex align-items-center justify-content-between py0 px24">
            <h3 className="fs14 fw600 cl-pale-sky min-width-80 shrink-0 shrink-0">
              {t("createdCampaigns")} {/* update for arabic */}
            </h3>
            <div className="height-decimal-5 flex-grow bg-cl-gallery ml10 w-100"></div>
          </div>
          <CampaignFilter
            filter={filter}
            setFilter={setFilter}
            isLoading={isLoading}
          />
          <InfiniteScroll
            dataLength={campaignData?.length}
            next={fetchMoreData}
            scrollableTarget="scrollableTarget"
            hasMore={campaignData?.length !== total}
            loader={
              !showLoader &&
              isLoading && (
                <div className="display-flex flex-grow w-100 align-items-center justify-content-center py16 px0">
                  <CircularProgress color="primary" />
                </div>
              )
            }
          >
            <>
              {showLoader ? (
                <div className="display-flex flex-grow w-100 align-items-center justify-content-center py16 px0 mt100">
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <div className={styles["my-campaigns__cards-container"]}>
                  {campaignData?.map(details => {
                    return (
                      <CampaignCard
                        key={details?.id}
                        refetchCampaign={() => {
                          setRefetch(prev => !prev)
                        }}
                        details={details}
                      />
                    )
                  })}
                </div>
              )}
            </>
          </InfiniteScroll>
        </>
      ) : (
        ""
      )}
    </div>
  )
}

const BalanceTiles = () => {
  const accountBalance = useAppSelector(selectAccountBalance)

  const totalBalance = accountBalance?.corporate_balance
  const reservedBudget = accountBalance?.allocated_campaign_balance
  const freeBalance = accountBalance?.free_balance
  return (
    <div className="display-flex gap24 py30 px24">
      <BalanceCard label={"totalBalance"} value={totalBalance} />
      <BalanceCard label={"allocatedCampaignBudget"} value={reservedBudget} />
      <BalanceCard label={"availableBalance"} value={freeBalance} />
    </div>
  )
}

export default MyCampaigns
