import { createAppSlice } from "@redux/createAppSlice"
import { type PayloadAction } from "@reduxjs/toolkit"

const initialState: { isCorporateSelected: boolean } = {
  isCorporateSelected: false,
}

export const corporateSelectionSlice = createAppSlice({
  name: "corporateSelectionSlice",
  initialState,
  reducers: create => ({
    updateCorporateSelection: create.reducer(
      (state, action: PayloadAction<{ isSelected: boolean }>) => {
        state.isCorporateSelected = action.payload.isSelected
      },
    ),
  }),
  selectors: {
    selectIsCorporateSelected: appData => appData.isCorporateSelected,
  },
})

export const { updateCorporateSelection } = corporateSelectionSlice.actions

export const { selectIsCorporateSelected } = corporateSelectionSlice.selectors
