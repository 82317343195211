enum API_ENDPOINTS {
  // Login APIs
  GET_O_AUTH_TOKEN = "oauth/token/",
  LOGIN = "login/",
  SEND_OTP = "send-otp/",
  VERIFY_OTP = "verify-otp/",
  FORGOT_PASSWORD = "forgot-password/",
  RESET_PASSWORD = "reset-password/",
  TOKEN_REFRESH = "token/refresh/",
  PROFILE = "profile/",
  CAMPAIGN = "campaign/",
  ACCOUNT_BALANCE = "account-balance/",
  // Campaign Payments,
  PAYMENT_BULK = "payment_bulk/",
  PAYMENT_EMAIL_SMS = "entries/submit/",
  MY_CAMPAIGNS = "my_campaigns/",
  LOGOUT = "logout/",
  // Dashboard
  DASHBOARD = "dashboard/",
  CHANGE_PASSWORD = "change-password/",
  UPDATE_DELIVERY = "email_sms_details/",
  SEND_TEST_EMAIL = "send_test_email/",
  SEND_TEST_SMS = "send_test_sms/",
  // Receiver details
  UPLOAD = "upload/",
  ORDER = "order/",
  ENTRIES = "entries/",
  SAMPLE_DOWNLOAD = "sample_file_download/",
  //REPORT
  REPORT = "reports_api/",
  INITIAL_CAMPAIGN_CHECK = "initial-campaign-check/",
  GET_CORPORATES = "corporates-selection/",
}
export const API_BASE_URL_GS_QA =
  "https://giftshop-frontend-es-776.sit.yougotagift.co"
export const API_BASE_URL_QA = "https://giftshop-es-776.sit.yougotagift.co"

export const API_SLUG = "you_campaign/api/v1/"

export const API_BASE_URL =
  import.meta.env.VITE_ENVIRONMENT === "qa"
    ? API_BASE_URL_QA
    : import.meta.env.VITE_YOUCAMPAIGN_API_BASE_URL

export default API_ENDPOINTS
