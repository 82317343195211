import { SearchIcon } from "@features/common/SvgIcons"
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  styled,
} from "@mui/material"
import { useTranslation } from "react-i18next"

interface FilterProps {
  search: string
  filters: { id: number; label: string; name: string; checked: boolean }[]
}
const StyledCheckbox = styled(Checkbox)(
  ({ theme, lng }: { theme?: any; lng: any }) => ({
    width: 15,
    height: 15,
    borderRadius: "2px",
    border: "1px solid #d8d8d8",
    backgroundColor: "#fff",
    padding: "8px",
    "&.Mui-checked": {
      backgroundColor: "#0071FF",
      border: "1px solid #0071FF",
      "&:before": {
        content: '""',
        position: "absolute",
        top: "35%",
        left: "50%",
        transform: `translate(-50%, -35%) rotate(45deg) ${lng === "ar" ? "scaleX(-1)" : "scaleX(1)"}`,
        width: "6px",
        height: "12px",
        border: "solid white",
        borderWidth: "0 2px 2px 0",
      },
    },
    "& .MuiSvgIcon-root": {
      display: "none", // Hide default checkmark
    },
  }),
)
const CampaignFilter: React.FC<{
  filter: FilterProps
  isLoading: boolean
  setFilter: React.Dispatch<React.SetStateAction<FilterProps>>
}> = ({ filter, setFilter, isLoading }) => {
  const {
    t,
    i18n: { language: lng },
  } = useTranslation()

  const { filters, search } = filter

  const searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(prev => ({
      ...prev,
      search: e.target.value,
    }))
  }

  const filterChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(prev => ({
      ...prev,
      filters: prev.filters?.map(el =>
        el.name === e.target.name ? { ...el, checked: e.target.checked } : el,
      ),
    }))
  }

  const clearFilterHandler = () => {
    setFilter(prev => ({
      search: "",
      filters: prev.filters?.map(el => ({ ...el, checked: false })),
    }))
  }

  return (
    <div className="pt16 px24 display-flex align-items-center gap24 flex-wrap-wrap">
      <div className="height35 bg-cl-aqua-haze display-flex brdr-secondary py8 px16 align-items-center justify-content-center width330">
        <SearchIcon />
        <input
          onChange={searchChangeHandler}
          value={search}
          className={`brdr-none bg-cl-aqua-haze w-100 ${lng === "ar" ? "mr3" : "ml3"} fs14 cl-pale-sky fw400`}
          placeholder={t("search")}
        />
      </div>

      <FormGroup onChange={filterChangeHandler}>
        <div className="display-flex align-items-center gap14 flex-wrap-wrap">
          {filters?.map(el => {
            return (
              <FormControlLabel
                key={el.id}
                disabled={isLoading}
                name={el.name}
                className="bg-cl-white py8 px16 brdr-secondary mr0 ml0 brdr-cl-gallery brdr-decimal-5"
                control={
                  <StyledCheckbox
                    lng={lng}
                    checked={el.checked}
                    disabled={isLoading}
                    inputProps={{ "aria-label": "controlled" }}
                    className={`${lng === "ar" ? "ml8" : "mr8"}`}
                  />
                }
                label={lng === "ar" ? t(el.label.toLowerCase()) : t(el.label)}
                classes={{ label: "cl-pale-sky fs14 fw500" }}
              />
            )
          })}
        </div>
      </FormGroup>

      <Button
        onClick={clearFilterHandler}
        sx={{ py: "8px" }}
        className="py8 px16 brdr-secondary"
      >
        <Typography
          color={"#6b7280"}
          fontSize={12}
          fontWeight={500}
          component="p"
        >
          {t("clearAll")}
        </Typography>
      </Button>
    </div>
  )
}

export default CampaignFilter
