import { Box } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { styled } from "@mui/material/styles"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import PrimaryButton from "../formElements/PrimaryButton"
import useSiteTranslation from "@features/createNewCampaign/giftShopConfig/giftShopHome/useSiteTranslation"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

interface ModalProps {
  children: React.ReactNode
  open: boolean
  handleClose: () => void
  title: string
  submitText?: string
  submitFunction: () => void
  isLoading?: boolean
  dir?: "ltr" | "rtl"
  cancelText?: string
  align?: "top" | "center"
  submitDisabled?: boolean
  handleCancel: () => void
  className?: string
}

export default function CtaModal({
  title,
  submitText,
  open,
  handleClose,
  submitFunction,
  children,
  dir,
  isLoading,
  cancelText,
  align,
  submitDisabled = false,
  handleCancel,
  className,
}: ModalProps) {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={`${className} mui-modal`}
        style={{}}
        sx={{
          direction: dir ? dir : "inherit",
          ".MuiPaper-root": {
            top: align === "center" ? "0px" : "120px",
          },
          ".MuiDialog-container": {
            alignItems: align === "center" ? "center" : "start",
          },
        }}
      >
        <Box sx={{ width: "612px", borderRadius: "12px" }}>
          <DialogTitle
            sx={{
              mb: "20px",
              p: 0,
              fontWeight: "600",
              fontSize: "24px",
              textAlign: language === "ar" ? "right" : "left",
            }}
            id="customized-dialog-title"
            style={{
              textAlign: language === "ar" ? "right" : "left",
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent
            className={`${language === "ar" && "text-align-right"}`}
          >
            {children}
          </DialogContent>

          {submitText && (
            <div className="mui-modal__actions">
              <Button
                sx={{
                  color: "#1f1f1f",
                  width: "100%",
                  background: "#f2f5f8",
                  outline: "none",
                }}
                onClick={handleCancel}
              >
                {cancelText}
              </Button>
              <PrimaryButton
                className="w-100"
                isLoading={isLoading}
                handleSubmit={submitFunction as any}
                label={submitText}
                disabled={submitDisabled}
              />
            </div>
          )}
        </Box>
      </BootstrapDialog>
    </Fragment>
  )
}
