import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import React from "react"
import PAGE_URLS from "@constants/pageUrls"
import PageLoader from "@features/common/pageLoader/PageLoader"
import MyCampaigns from "@pages/myCampaign/MyCampaign.Page"
import LocaleRoute from "./LocaleRoute"
import BasePathRoute from "./BasePathRoute"
import { useTranslation } from "react-i18next"
import PrivateRoute from "./PrivateRoute"
import ErrorNavigator from "./ErrorNavigator"
import { ErrorBoundary } from "@sentry/react"
// layouts
const LoginLayout = React.lazy(() => import("@layouts/loginLayout/LoginLayout"))
const DashboardLayout = React.lazy(
  () => import("@layouts/dashboardLayout/DashboardLayout"),
)
const PreviewLayout = React.lazy(
  () => import("@layouts/previewLayout/PreviewLayout"),
)
const CampaignLayout = React.lazy(
  () => import("@layouts/campaignLayout/CampaignLayout"),
)
const ReportLayout = React.lazy(
  () => import("@layouts/reportLayout/ReportLayout"),
)
const CommonLayout = React.lazy(
  () => import("@layouts/commonLayout/CommonLayout"),
)

// pages
const LoginPage = React.lazy(() => import("@pages/login/Login.Page"))
const ForgotPasswordPage = React.lazy(
  () => import("@pages/forgotPassword/ForgotPassword.Page"),
)
const ResetPasswordPage = React.lazy(
  () => import("@pages/resetPassword/ResetPassword.Page"),
)
const DashboardPage = React.lazy(() => import("@pages/home/Home.Page"))
const CreateCampaign = React.lazy(
  () => import("@pages/createNewCampaign/CreateCampaign.Page"),
)
const DeliverCampaign = React.lazy(
  () => import("@pages/deliverCampaigns/DeliverCampaign.Page"),
)

const CampaignPayment = React.lazy(
  () => import("@pages/campaignPayment/CampaignPayment.Page"),
)

const CampaignBulkDownload = React.lazy(
  () => import("@pages/campaignBulkDownload/CampaignBulkDownload.Page"),
)

const MyCampaignView = React.lazy(
  () => import("pages/campaignPreview/CampaignPreview.page"),
)
const CorporateSelection = React.lazy(
  () => import("@pages/corporateSelection/CorporateSelection.Page"),
)

// error Pages

const NotFoundPage = React.lazy(() => import("@pages/error/NotFound.Page"))
const BaseNotFoundPage = React.lazy(
  () => import("@pages/error/BaseNotFound.Page"),
)

const SessionExpiredPage = React.lazy(
  () => import("@pages/error/SessionExpired.Page"),
)

//Reports

const Reports = React.lazy(() => import("@pages/reports/Reports.Page"))

const Router = () => {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <BrowserRouter>
      <ErrorBoundary fallback={<p>An error has occurred</p>}>
        <React.Suspense fallback={<PageLoader />}>
          <Routes>
            <Route element={<ErrorNavigator />}>
              <Route path={":basepath"} element={<BasePathRoute />}>
                <Route path={":locale"} element={<LocaleRoute />}>
                  <Route element={<LoginLayout />}>
                    <Route path={PAGE_URLS.LOGIN} element={<LoginPage />} />
                    <Route
                      path={PAGE_URLS.FORGOT_PASSWORD}
                      element={<ForgotPasswordPage />}
                    />
                    <Route
                      path={PAGE_URLS.RESET_PASSWORD}
                      element={<ResetPasswordPage />}
                    />
                  </Route>
                  {/* Private Routing */}
                  <Route element={<PrivateRoute />}>
                    <Route
                      element={<CorporateSelection />}
                      path={PAGE_URLS.SELECT_CORPORATE}
                    />
                    <Route element={<CommonLayout />}>
                      <Route element={<PreviewLayout />}>
                        <Route
                          element={<MyCampaignView />}
                          path={`${PAGE_URLS.MY_CAMPAIGNS}/:id`}
                        />
                      </Route>
                      <Route element={<DashboardLayout />}>
                        <Route
                          index
                          element={
                            <Navigate to={PAGE_URLS.DASHBOARD} replace />
                          }
                        />
                        <Route
                          path={PAGE_URLS.DASHBOARD}
                          element={<DashboardPage />}
                        />

                        <Route
                          element={<MyCampaigns />}
                          path={PAGE_URLS.MY_CAMPAIGNS}
                        />
                      </Route>
                      <Route element={<ReportLayout />}>
                        <Route
                          element={<Reports />}
                          path={`${PAGE_URLS.REPORT}/:reportType`}
                        />
                      </Route>

                      <Route element={<CampaignLayout />}>
                        <Route
                          index
                          path={`${PAGE_URLS.CREATE_CAMPAIGN}/:step/:id?`}
                          element={<CreateCampaign />}
                        />
                        <Route
                          index
                          path={`${PAGE_URLS.DELIVER_CAMPAIGN}/:id`}
                          element={<DeliverCampaign />}
                        />
                        <Route
                          index
                          path={`${PAGE_URLS.CAMPAIGN_PAYMENT}/:deliveryType`}
                          element={<CampaignPayment />}
                        />
                        <Route
                          index
                          path={`${PAGE_URLS.CAMPAIGN_PAYMENT}/:deliveryType/download`}
                          element={<CampaignBulkDownload />}
                        />
                        <Route
                          index
                          path={`${PAGE_URLS.CAMPAIGN_PAYMENT}/:deliveryType/:id`}
                          element={<CampaignPayment />}
                        />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    path="*"
                    element={
                      <Navigate to={`../${PAGE_URLS.NOT_FOUND}`} replace />
                    }
                  />
                  <Route
                    path={PAGE_URLS.NOT_FOUND}
                    element={<NotFoundPage />}
                  />
                  <Route
                    path={PAGE_URLS.SESSION_EXPIRED}
                    element={<SessionExpiredPage />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path="/" element={<BaseNotFoundPage />} />
            <Route
              path={`/${PAGE_URLS.BASE_PATH}`}
              element={
                <Navigate
                  to={`/${PAGE_URLS.BASE_PATH}/${language}/dashboard`}
                  replace
                />
              }
            />
            <Route
              path={`/${PAGE_URLS.BASE_PATH}/${language}`}
              element={
                <Navigate
                  to={`/${PAGE_URLS.BASE_PATH}/${language}/dashboard`}
                  replace
                />
              }
            />
          </Routes>
        </React.Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default Router
