import { COLORS } from "@constants/common"

export const HomeIcon = ({ fill }: { fill: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 18.984V10.35a1.028 1.028 0 0 0-.4-.812l-7-5.334a.99.99 0 0 0-1.2 0l-7 5.334a1.012 1.012 0 0 0-.4.812v8.635c0 .27.105.528.293.718.187.191.442.298.707.298h4c.265 0 .52-.107.707-.297.188-.191.293-.45.293-.719v-3.048c0-.269.105-.527.293-.718a.992.992 0 0 1 .707-.297h2c.265 0 .52.107.707.297.188.19.293.45.293.719v3.047c0 .27.105.528.293.718.187.191.442.298.707.298h4c.265 0 .52-.107.707-.297.188-.191.293-.45.293-.719z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const TimerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9.089 17a7.967 7.967 0 0 1-3.155-.626 8.14 8.14 0 0 1-2.568-1.714 8.117 8.117 0 0 1-1.733-2.54A7.735 7.735 0 0 1 1 9c0-1.11.211-2.15.633-3.12.422-.97 1-1.817 1.733-2.54a8.264 8.264 0 0 1 2.568-1.714A7.866 7.866 0 0 1 9.09 1a7.97 7.97 0 0 1 3.318.712 8.383 8.383 0 0 1 2.748 1.984V1.808a.49.49 0 0 1 .507-.5c.143 0 .263.048.36.143a.48.48 0 0 1 .144.357v2.846c0 .229-.078.42-.235.575a.798.798 0 0 1-.581.233h-2.878a.491.491 0 0 1-.505-.501.496.496 0 0 1 .506-.5h2.001a7.846 7.846 0 0 0-2.437-1.805A6.778 6.778 0 0 0 9.09 2c-1.972 0-3.645.68-5.018 2.038C2.698 5.397 2.01 7.051 2.01 9c-.002 1.95.685 3.604 2.06 4.963 1.374 1.36 3.047 2.038 5.017 2.037 1.666 0 3.14-.517 4.424-1.55 1.282-1.033 2.107-2.345 2.474-3.934a.654.654 0 0 1 .23-.35.489.489 0 0 1 .38-.093c.153.02.265.093.336.218a.512.512 0 0 1 .045.409c-.385 1.843-1.31 3.353-2.777 4.532C12.734 16.411 11.031 17 9.09 17zm.505-8.208 3.033 3a.502.502 0 0 1 .152.344.463.463 0 0 1-.152.364.5.5 0 0 1-.358.16.496.496 0 0 1-.357-.16L8.828 9.45a.794.794 0 0 1-.189-.274.805.805 0 0 1-.057-.297V4.5A.493.493 0 0 1 9.09 4a.49.49 0 0 1 .505.5v4.292z"
        fill="#6B7280"
        stroke="#6B7280"
        strokeWidth=".5"
      />
    </svg>
  )
}

export const LockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15 8.95841C14.6583 8.95841 14.375 8.67508 14.375 8.33341V6.66675C14.375 4.04175 13.6333 2.29175 10 2.29175C6.36667 2.29175 5.625 4.04175 5.625 6.66675V8.33341C5.625 8.67508 5.34167 8.95841 5 8.95841C4.65833 8.95841 4.375 8.67508 4.375 8.33341V6.66675C4.375 4.25008 4.95833 1.04175 10 1.04175C15.0417 1.04175 15.625 4.25008 15.625 6.66675V8.33341C15.625 8.67508 15.3417 8.95841 15 8.95841Z"
        fill="#292D32"
      />
      <path
        d="M10.0001 16.0417C8.50841 16.0417 7.29175 14.825 7.29175 13.3333C7.29175 11.8417 8.50841 10.625 10.0001 10.625C11.4917 10.625 12.7084 11.8417 12.7084 13.3333C12.7084 14.825 11.4917 16.0417 10.0001 16.0417ZM10.0001 11.875C9.20008 11.875 8.54175 12.5333 8.54175 13.3333C8.54175 14.1333 9.20008 14.7917 10.0001 14.7917C10.8001 14.7917 11.4584 14.1333 11.4584 13.3333C11.4584 12.5333 10.8001 11.875 10.0001 11.875Z"
        fill="#292D32"
      />
      <path
        d="M14.1667 18.9583H5.83341C2.15841 18.9583 1.04175 17.8416 1.04175 14.1666V12.4999C1.04175 8.82492 2.15841 7.70825 5.83341 7.70825H14.1667C17.8417 7.70825 18.9584 8.82492 18.9584 12.4999V14.1666C18.9584 17.8416 17.8417 18.9583 14.1667 18.9583ZM5.83341 8.95825C2.85008 8.95825 2.29175 9.52492 2.29175 12.4999V14.1666C2.29175 17.1416 2.85008 17.7083 5.83341 17.7083H14.1667C17.1501 17.7083 17.7084 17.1416 17.7084 14.1666V12.4999C17.7084 9.52492 17.1501 8.95825 14.1667 8.95825H5.83341Z"
        fill="#292D32"
      />
    </svg>
  )
}

export const LogoutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M6.30003 17.5583H6.40836C10.1084 17.5583 11.8917 16.1 12.2 12.8333C12.2334 12.4917 11.9834 12.1833 11.6334 12.15C11.3 12.1166 10.9834 12.375 10.95 12.7167C10.7084 15.3333 9.47503 16.3083 6.40003 16.3083H6.2917C2.90003 16.3083 1.70003 15.1083 1.70003 11.7167V6.28332C1.70003 2.89165 2.90003 1.69165 6.2917 1.69165H6.40003C9.4917 1.69165 10.725 2.68332 10.95 5.34998C10.9917 5.69165 11.2834 5.94998 11.6334 5.91665C11.7155 5.91023 11.7955 5.88766 11.8689 5.85024C11.9423 5.81281 12.0076 5.76126 12.061 5.69855C12.1144 5.63584 12.155 5.5632 12.1802 5.48479C12.2055 5.40638 12.2151 5.32376 12.2084 5.24165C11.925 1.92498 10.1334 0.44165 6.40836 0.44165H6.30003C2.20836 0.44165 0.458362 2.19165 0.458362 6.28332V11.7167C0.458362 15.8083 2.20836 17.5583 6.30003 17.5583Z"
        fill="#E90606"
      />
      <path
        d="M6.50006 9.625H15.9834C16.3251 9.625 16.6084 9.34167 16.6084 9C16.6084 8.65833 16.3251 8.375 15.9834 8.375H6.50006C6.1584 8.375 5.87506 8.65833 5.87506 9C5.87506 9.34167 6.1584 9.625 6.50006 9.625Z"
        fill="#E90606"
      />
      <path
        d="M14.125 12.4167C14.2834 12.4167 14.4417 12.3584 14.5667 12.2334L17.3584 9.4417C17.4746 9.32408 17.5398 9.16539 17.5398 9.00003C17.5398 8.83467 17.4746 8.67598 17.3584 8.55837L14.5667 5.7667C14.325 5.52503 13.925 5.52503 13.6834 5.7667C13.4417 6.00837 13.4417 6.40837 13.6834 6.65003L16.0334 9.00003L13.6834 11.35C13.4417 11.5917 13.4417 11.9917 13.6834 12.2334C13.8 12.3584 13.9667 12.4167 14.125 12.4167Z"
        fill="#E90606"
      />
    </svg>
  )
}

export const EyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 16.3299C9.61004 16.3299 7.67004 14.3899 7.67004 11.9999C7.67004 9.60992 9.61004 7.66992 12 7.66992C14.39 7.66992 16.33 9.60992 16.33 11.9999C16.33 14.3899 14.39 16.3299 12 16.3299ZM12 9.16992C10.44 9.16992 9.17004 10.4399 9.17004 11.9999C9.17004 13.5599 10.44 14.8299 12 14.8299C13.56 14.8299 14.83 13.5599 14.83 11.9999C14.83 10.4399 13.56 9.16992 12 9.16992Z"
        fill="#6B7280"
      />
      <path
        d="M12 21.02C8.23996 21.02 4.68996 18.82 2.24996 15C1.18996 13.35 1.18996 10.66 2.24996 8.99998C4.69996 5.17998 8.24996 2.97998 12 2.97998C15.75 2.97998 19.3 5.17998 21.74 8.99998C22.8 10.65 22.8 13.34 21.74 15C19.3 18.82 15.75 21.02 12 21.02ZM12 4.47998C8.76996 4.47998 5.67996 6.41998 3.51996 9.80998C2.76996 10.98 2.76996 13.02 3.51996 14.19C5.67996 17.58 8.76996 19.52 12 19.52C15.23 19.52 18.32 17.58 20.48 14.19C21.23 13.02 21.23 10.98 20.48 9.80998C18.32 6.41998 15.23 4.47998 12 4.47998Z"
        fill="#6B7280"
      />
    </svg>
  )
}

export const EyeCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.47004 15.2799C9.28004 15.2799 9.09004 15.2099 8.94004 15.0599C8.12004 14.2399 7.67004 13.1499 7.67004 11.9999C7.67004 9.60992 9.61004 7.66992 12 7.66992C13.15 7.66992 14.24 8.11992 15.06 8.93992C15.2 9.07992 15.28 9.26992 15.28 9.46992C15.28 9.66992 15.2 9.85992 15.06 9.99992L10 15.0599C9.85004 15.2099 9.66004 15.2799 9.47004 15.2799ZM12 9.16992C10.44 9.16992 9.17004 10.4399 9.17004 11.9999C9.17004 12.4999 9.30004 12.9799 9.54004 13.3999L13.4 9.53992C12.98 9.29992 12.5 9.16992 12 9.16992Z"
        fill="#6B7280"
      />
      <path
        d="M5.59997 18.51C5.42997 18.51 5.24997 18.45 5.10997 18.33C4.03997 17.42 3.07997 16.3 2.25997 15C1.19997 13.35 1.19997 10.66 2.25997 8.99998C4.69997 5.17998 8.24997 2.97998 12 2.97998C14.2 2.97998 16.37 3.73998 18.27 5.16998C18.6 5.41998 18.67 5.88998 18.42 6.21998C18.17 6.54998 17.7 6.61998 17.37 6.36998C15.73 5.12998 13.87 4.47998 12 4.47998C8.76997 4.47998 5.67997 6.41998 3.51997 9.80998C2.76997 10.98 2.76997 13.02 3.51997 14.19C4.26997 15.36 5.12997 16.37 6.07997 17.19C6.38997 17.46 6.42997 17.93 6.15997 18.25C6.01997 18.42 5.80997 18.51 5.59997 18.51Z"
        fill="#6B7280"
      />
      <path
        d="M11.9999 21.02C10.6699 21.02 9.36994 20.75 8.11994 20.22C7.73994 20.06 7.55994 19.62 7.71994 19.24C7.87994 18.86 8.31994 18.68 8.69994 18.84C9.75994 19.29 10.8699 19.52 11.9899 19.52C15.2199 19.52 18.3099 17.58 20.4699 14.19C21.2199 13.02 21.2199 10.98 20.4699 9.81C20.1599 9.32 19.8199 8.85 19.4599 8.41C19.1999 8.09 19.2499 7.62 19.5699 7.35C19.8899 7.09 20.3599 7.13 20.6299 7.46C21.0199 7.94 21.3999 8.46 21.7399 9C22.7999 10.65 22.7999 13.34 21.7399 15C19.2999 18.82 15.7499 21.02 11.9999 21.02Z"
        fill="#6B7280"
      />
      <path
        d="M12.69 16.2701C12.34 16.2701 12.02 16.0201 11.95 15.6601C11.87 15.2501 12.14 14.8601 12.55 14.7901C13.65 14.5901 14.57 13.6701 14.77 12.5701C14.85 12.1601 15.24 11.9001 15.65 11.9701C16.06 12.0501 16.33 12.4401 16.25 12.8501C15.93 14.5801 14.55 15.9501 12.83 16.2701C12.78 16.2601 12.74 16.2701 12.69 16.2701Z"
        fill="#6B7280"
      />
      <path
        d="M1.99994 22.75C1.80994 22.75 1.61994 22.68 1.46994 22.53C1.17994 22.24 1.17994 21.76 1.46994 21.47L8.93994 14C9.22994 13.71 9.70994 13.71 9.99994 14C10.2899 14.29 10.2899 14.77 9.99994 15.06L2.52994 22.53C2.37994 22.68 2.18994 22.75 1.99994 22.75Z"
        fill="#6B7280"
      />
      <path
        d="M14.53 10.2199C14.34 10.2199 14.15 10.1499 14 9.99994C13.71 9.70994 13.71 9.22994 14 8.93994L21.47 1.46994C21.76 1.17994 22.24 1.17994 22.53 1.46994C22.82 1.75994 22.82 2.23994 22.53 2.52994L15.06 9.99994C14.91 10.1499 14.72 10.2199 14.53 10.2199Z"
        fill="#6B7280"
      />
    </svg>
  )
}

export const NotValidIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7.00004 0.333252C3.32671 0.333252 0.333374 3.32659 0.333374 6.99992C0.333374 10.6733 3.32671 13.6666 7.00004 13.6666C10.6734 13.6666 13.6667 10.6733 13.6667 6.99992C13.6667 3.32659 10.6734 0.333252 7.00004 0.333252ZM9.24004 8.53325C9.43337 8.72658 9.43337 9.04658 9.24004 9.23992C9.14004 9.33992 9.01337 9.38658 8.88671 9.38658C8.76004 9.38658 8.63337 9.33992 8.53337 9.23992L7.00004 7.70658L5.46671 9.23992C5.36671 9.33992 5.24004 9.38658 5.11337 9.38658C4.98671 9.38658 4.86004 9.33992 4.76004 9.23992C4.56671 9.04658 4.56671 8.72658 4.76004 8.53325L6.29337 6.99992L4.76004 5.46658C4.56671 5.27325 4.56671 4.95325 4.76004 4.75992C4.95337 4.56659 5.27337 4.56659 5.46671 4.75992L7.00004 6.29325L8.53337 4.75992C8.72671 4.56659 9.04671 4.56659 9.24004 4.75992C9.43337 4.95325 9.43337 5.27325 9.24004 5.46658L7.70671 6.99992L9.24004 8.53325Z"
        fill="#6B7280"
      />
    </svg>
  )
}

export const ValidIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.99992 0.333252C3.32659 0.333252 0.333252 3.32659 0.333252 6.99992C0.333252 10.6733 3.32659 13.6666 6.99992 13.6666C10.6733 13.6666 13.6666 10.6733 13.6666 6.99992C13.6666 3.32659 10.6733 0.333252 6.99992 0.333252ZM10.1866 5.46659L6.40659 9.24659C6.31325 9.33992 6.18659 9.39325 6.05325 9.39325C5.91992 9.39325 5.79325 9.33992 5.69992 9.24659L3.81325 7.35992C3.61992 7.16659 3.61992 6.84659 3.81325 6.65325C4.00659 6.45992 4.32659 6.45992 4.51992 6.65325L6.05325 8.18659L9.47992 4.75992C9.67325 4.56659 9.99325 4.56659 10.1866 4.75992C10.3799 4.95325 10.3799 5.26659 10.1866 5.46659Z"
        fill="#14A764"
      />
    </svg>
  )
}

export const EditIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M0 12.9231H15.2727V14H0V12.9231ZM12.7636 3.76923C13.2 3.33846 13.2 2.69231 12.7636 2.26154L10.8 0.323077C10.3636 -0.107692 9.70909 -0.107692 9.27273 0.323077L1.09091 8.4V11.8462H4.58182L12.7636 3.76923ZM10.0364 1.07692L12 3.01538L10.3636 4.63077L8.4 2.69231L10.0364 1.07692ZM2.18182 10.7692V8.83077L7.63636 3.44615L9.6 5.38462L4.14545 10.7692H2.18182Z"
        fill={fill}
      />
    </svg>
  )
}
export const SubtractIcon = ({ fill }: { fill?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
  >
    <path
      d="M13.1826 25C19.8831 25 25.3652 19.6 25.3652 13C25.3652 6.4 19.8831 1 13.1826 1C6.48218 1 1 6.4 1 13C1 19.6 6.48218 25 13.1826 25Z"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.30957 12.9999H18.0557"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const AdditionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M12.5 24C18.825 24 24 18.825 24 12.5C24 6.175 18.825 1 12.5 1C6.175 1 1 6.175 1 12.5C1 18.825 6.175 24 12.5 24Z"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.8999 12.5H17.0999"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 17.1V7.89996"
      stroke="#6B7280"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const AddIcon = ({ fill }: { fill: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18zm0 16.893c-4.342 0-7.875-3.55-7.875-7.893 0-4.342 3.533-7.875 7.875-7.875 4.342 0 7.875 3.533 7.875 7.875 0 4.342-3.533 7.893-7.875 7.893zm3.938-8.456h-3.376V8.063a.563.563 0 0 0-1.124 0v3.374H8.062a.563.563 0 0 0 0 1.126h3.376v3.374a.563.563 0 0 0 1.124 0v-3.374h3.376a.563.563 0 0 0 0-1.126z"
      stroke={fill}
      fill={fill}
      strokeWidth=".5"
    />
  </svg>
)

export const WalletIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.8 6.2h4.8"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6 7.4h-3.123C18.335 7.4 16.6 9.012 16.6 11s1.736 3.6 3.876 3.6H23.6c.1 0 .15 0 .192-.002.648-.04 1.164-.519 1.206-1.12.002-.038.002-.085.002-.178V8.7c0-.093 0-.14-.002-.178-.044-.601-.558-1.08-1.206-1.12C23.75 7.4 23.7 7.4 23.6 7.4z"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M23.758 7.4c-.094-2.246-.394-3.624-1.364-4.594C20.988 1.4 18.725 1.4 14.2 1.4h-3.6c-4.525 0-6.788 0-8.194 1.406C1 4.212 1 6.475 1 11s0 6.788 1.406 8.194C3.812 20.6 6.075 20.6 10.6 20.6h3.6c4.525 0 6.788 0 8.194-1.406.97-.97 1.272-2.348 1.364-4.594"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M20.19 11h.011"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const StepCheckIcon = ({
  fill = COLORS.GREEN_COLOR,
}: {
  fill?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
      fill={fill}
    />
    <path
      d="M8.75 14.75L12.25 18.25L19.25 10.75"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const StepCompletedIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 27.5c6.875 0 12.5-5.625 12.5-12.5S21.875 2.5 15 2.5 2.5 8.125 2.5 15 8.125 27.5 15 27.5z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m9.688 15 3.537 3.537 7.088-7.075"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const ArrowDownBlueIcon = ({
  fill = COLORS.PRIMARY_COLOR,
  className = "",
  size = "16",
}: {
  fill?: string
  className?: string
  size?: string
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.75A6.26 6.26 0 0 0 1.75 8 6.26 6.26 0 0 0 8 14.25 6.26 6.26 0 0 0 14.25 8 6.26 6.26 0 0 0 8 1.75zm2.537 5.794L8.332 9.75A.464.464 0 0 1 8 9.887a.464.464 0 0 1-.331-.137L5.463 7.544a.472.472 0 0 1 0-.663.472.472 0 0 1 .662 0L8 8.756l1.875-1.875a.472.472 0 0 1 .662 0 .472.472 0 0 1 0 .663z"
        fill={fill}
      />
    </svg>
  )
}

export const StepInProgressIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 27.5c6.875 0 12.5-5.625 12.5-12.5S21.875 2.5 15 2.5 2.5 8.125 2.5 15 8.125 27.5 15 27.5z"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="3 4"
    />
  </svg>
)

export const DownArrowIcon = ({
  height = "7",
  width = "12",
}: {
  width?: string
  height?: string
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.762 1.689 6.443 6.348a.573.573 0 0 1-.206.118A.776.776 0 0 1 6 6.5a.8.8 0 0 1-.237-.034.562.562 0 0 1-.206-.118L.222 1.688A.616.616 0 0 1 0 1.206c0-.193.08-.36.237-.498A.816.816 0 0 1 .792.5c.21 0 .395.07.554.207L6 4.772 10.654.707a.801.801 0 0 1 .547-.193c.216 0 .404.069.561.207.159.138.238.3.238.484s-.08.346-.238.484z"
        fill="#1F1F1F"
      />
    </svg>
  )
}

export const PlayIcon = ({ fill }: { fill?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.463c-4.707 0-8.537 3.83-8.537 8.537 0 4.707 3.83 8.537 8.537 8.537 4.707 0 8.537-3.83 8.537-8.537 0-4.707-3.83-8.537-8.537-8.537zM10 20C4.486 20 0 15.514 0 10S4.486 0 10 0s10 4.486 10 10-4.486 10-10 10z"
      fill="#0071FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.911 7.89c-.173.976-.176 3.308-.003 4.235.857-.302 2.712-1.522 3.212-2.126-.498-.586-2.33-1.78-3.209-2.11zm-.298 5.77a.975.975 0 0 1-.718-.278c-.157-.155-.576-.57-.578-3.3-.001-2.9.435-3.331.578-3.475.51-.505 1.342-.159 1.696-.01.507.211 4.067 2.035 4.067 3.398 0 1.279-3.204 3.066-4.022 3.407-.32.133-.683.258-1.023.258z"
      fill="#0071FF"
    />
  </svg>
)

export const FaqIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#069trqm54a)"
        stroke="#6B7280"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10 18.333a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 0 0 0 16.667zM10 13.333V10m0-3.333h.008" />
      </g>
      <defs>
        <clipPath id="069trqm54a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const DeleteIcon = ({
  fill = COLORS.RED_COLOR,
  width = "12",
  height = "14",
}: {
  fill?: string
  width?: string
  height?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.20546 17.3334C7.07629 17.3334 5.97546 17.3209 4.88629 17.2984C3.49296 17.2709 2.52879 16.3675 2.37129 14.9409C2.10879 12.5742 1.65962 6.99588 1.65546 6.94004C1.62712 6.59588 1.88379 6.29421 2.22796 6.26671C2.56712 6.25754 2.87379 6.49588 2.90129 6.83921C2.90546 6.89588 3.35379 12.455 3.61379 14.8034C3.70296 15.6142 4.14046 16.0325 4.91212 16.0484C6.99546 16.0925 9.12129 16.095 11.413 16.0534C12.233 16.0375 12.6763 15.6275 12.768 14.7975C13.0263 12.4692 13.4763 6.89588 13.4813 6.83921C13.5088 6.49588 13.813 6.25588 14.1538 6.26671C14.498 6.29504 14.7546 6.59588 14.7271 6.94004C14.7221 6.99671 14.2705 12.5892 14.0105 14.935C13.8488 16.3909 12.8871 17.2767 11.4355 17.3034C10.3246 17.3225 9.25296 17.3334 8.20546 17.3334Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2567 4.82446H1.125C0.78 4.82446 0.5 4.54446 0.5 4.19946C0.5 3.85446 0.78 3.57446 1.125 3.57446H15.2567C15.6017 3.57446 15.8817 3.85446 15.8817 4.19946C15.8817 4.54446 15.6017 4.82446 15.2567 4.82446Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5335 4.82449C11.5852 4.82449 10.7618 4.14866 10.5752 3.21866L10.3727 2.20533C10.3302 2.05116 10.1543 1.91699 9.95432 1.91699H6.42682C6.22682 1.91699 6.05099 2.05116 6.00016 2.24366L5.80599 3.21866C5.62016 4.14866 4.79599 4.82449 3.84766 4.82449C3.50266 4.82449 3.22266 4.54449 3.22266 4.19949C3.22266 3.85449 3.50266 3.57449 3.84766 3.57449C4.20266 3.57449 4.51099 3.32116 4.58099 2.97283L4.78349 1.95949C4.98932 1.18283 5.66182 0.666992 6.42682 0.666992H9.95432C10.7193 0.666992 11.3918 1.18283 11.5893 1.92199L11.801 2.97283C11.8702 3.32116 12.1785 3.57449 12.5335 3.57449C12.8785 3.57449 13.1585 3.85449 13.1585 4.19949C13.1585 4.54449 12.8785 4.82449 12.5335 4.82449Z"
        fill={fill}
      />
    </svg>
  )
}

export const PhoneIcon = ({ fill = "#C10" }: { fill?: string }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.816 6.059a11.782 11.782 0 0 0 5.125 5.125l1.711-1.71c.21-.21.521-.28.794-.187a8.872 8.872 0 0 0 2.776.443.78.78 0 0 1 .778.778v2.714a.78.78 0 0 1-.778.778C5.92 14 0 8.081 0 .778A.78.78 0 0 1 .778 0H3.5a.78.78 0 0 1 .778.778c0 .972.155 1.905.443 2.776a.78.78 0 0 1-.194.794l-1.711 1.71z"
        fill={fill}
      />
    </svg>
  )
}

export const QuestionFilledIcon = ({ fill = "#C10" }: { fill?: string }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14zM4.311 4.366c-.25.376-.373.747-.373 1.112 0 .177.078.343.236.495a.8.8 0 0 0 .578.227c.388 0 .651-.217.79-.652.147-.416.327-.731.54-.946.212-.213.542-.32.992-.32.384 0 .697.105.94.318a.998.998 0 0 1 .364.78.849.849 0 0 1-.119.44c-.08.135-.18.258-.294.366-.184.169-.373.331-.567.489-.297.246-.534.459-.71.638a2.14 2.14 0 0 0-.423.624c-.282 1.09 1.181 1.177 1.519.4.041-.076.103-.158.186-.25a4.31 4.31 0 0 1 .335-.314c.354-.295.702-.597 1.044-.905a2.65 2.65 0 0 0 .502-.639c.145-.263.218-.56.211-.86 0-.417-.123-.801-.37-1.156-.247-.355-.597-.635-1.05-.842-.454-.205-.976-.308-1.568-.308-.638 0-1.195.122-1.673.37-.477.246-.84.558-1.09.933zm1.873 6.195a.875.875 0 1 0 1.75 0 .875.875 0 0 0-1.75 0z"
        fill={fill}
      />
    </svg>
  )
}

export const AlertIcon = ({
  color = "#CC1100",
  size = "22",
  fill = "none",
}: {
  color?: string
  size?: string
  fill?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill={fill}
    >
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 15V11M11 7H11.01"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SuccessTickIcon = ({ fill = "#C10" }: { fill?: string }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14z" fill={fill} />
      <path
        d="M9.885 5.748 6.443 8.864a.533.533 0 0 1-.706 0l-1.62-1.467A.427.427 0 0 1 4 7.086a.435.435 0 0 1 .147-.3.536.536 0 0 1 .675-.028L6.09 7.901l3.089-2.796a.536.536 0 0 1 .675.028c.089.08.14.187.146.3a.427.427 0 0 1-.115.311v.004z"
        fill="#fff"
      />
    </svg>
  )
}

export const InfoIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => {
  return (
    <svg
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.064.88c-.813 0-1.607.224-2.282.644a3.894 3.894 0 0 0-1.513 1.714 3.57 3.57 0 0 0-.234 2.206c.159.74.55 1.421 1.124 1.955a4.212 4.212 0 0 0 2.103 1.045 4.396 4.396 0 0 0 2.373-.217A4.058 4.058 0 0 0 8.48 6.821a3.631 3.631 0 0 0 .692-2.122c0-1.013-.433-1.984-1.203-2.7A4.27 4.27 0 0 0 5.064.88zm0 7a3.61 3.61 0 0 1-1.902-.536 3.244 3.244 0 0 1-1.26-1.428 2.975 2.975 0 0 1-.195-1.839 3.12 3.12 0 0 1 .936-1.63 3.51 3.51 0 0 1 1.753-.87 3.663 3.663 0 0 1 1.977.181c.626.24 1.16.649 1.536 1.172a2.99 2.99 0 0 1 .316 2.986 3.178 3.178 0 0 1-.741 1.032 3.45 3.45 0 0 1-1.11.69c-.416.16-.861.242-1.31.242z"
        fill={fill}
      />
      <path
        d="M5.064 3.58c.189 0 .342-.143.342-.319 0-.176-.153-.318-.342-.318-.19 0-.343.142-.343.318 0 .176.153.318.343.318zM5.405 4.058H4.72v2.39h.684v-2.39z"
        fill={fill}
      />
    </svg>
  )
}

export const MailIcon = ({ fill = "#C10" }: { fill?: string }) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.000976562 0V12H15.999V0H-0.000976562ZM7.99902 8L1.99902 2H13.999L7.99902 8Z"
        fill={fill}
      />
    </svg>
  )
}

export const ArrowRightIcon = ({
  fill = COLORS.PRIMARY_COLOR,
}: {
  fill?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="m2.196.44 8.396 4.86a.805.805 0 0 1 .408.705.818.818 0 0 1-.408.706l-8.4 4.852a.792.792 0 0 1-.906-.086.81.81 0 0 1-.24-.888l1.55-4.578v-.01l-1.55-4.588a.819.819 0 0 1 .24-.891.795.795 0 0 1 .91-.083zm-.392.708 1.504 4.448H6.2c.106 0 .208.043.283.118a.407.407 0 0 1-.283.692h-2.89l-1.507 4.446-.003.007 8.4-4.851-8.4-4.865.003.005z"
        fill={fill}
        stroke={fill}
        strokeWidth=".5"
      />
    </svg>
  )
}

export const RemoveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.28 18.919A8.573 8.573 0 0 1 2.43 11a8.571 8.571 0 1 1 11.851 7.919zM3.93 18.071A10 10 0 1 0 18.07 3.93 10 10 0 0 0 3.93 18.07zm10.642-7.785H7.43a.714.714 0 0 0 0 1.428h7.142a.714.714 0 1 0 0-1.428z"
        fill="#E90606"
        stroke="#E90606"
        strokeWidth=".2"
      />
    </svg>
  )
}

//balance card icons
export const TotalBalanceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <g clip-path="url(#clip0_4433_314)">
        <path
          d="M9.99 5.62499H20.01L22.1725 3.89499C22.9088 3.30749 23.1838 2.35374 22.8725 1.46374C22.5613 0.573742 21.7525 -7.62939e-06 20.8125 -7.62939e-06H9.1875C8.2475 -7.62939e-06 7.43875 0.574992 7.1275 1.46374C6.81625 2.35249 7.09125 3.30749 7.82625 3.89374L9.99 5.62499Z"
          fill="#FFBB38"
        />
        <path
          d="M20.1813 7.5H9.81875C6.405 10.8512 3.75 16.7312 3.75 21.5625C3.75 25.7612 5.9725 30 10.9375 30H19.375C23.6162 30 26.25 26.7663 26.25 21.5625C26.25 16.7312 23.595 10.8512 20.1813 7.5ZM14.525 17.8125H15.475C16.9362 17.8125 18.125 19.0013 18.125 20.4625C18.125 21.7763 17.1775 22.8575 15.9375 23.0775V24.0613C15.9375 24.5788 15.5175 24.9988 15 24.9988C14.4825 24.9988 14.0625 24.5788 14.0625 24.0613V23.125H12.8125C12.295 23.125 11.875 22.705 11.875 22.1875C11.875 21.67 12.295 21.25 12.8125 21.25H15.475C15.9025 21.25 16.25 20.9025 16.25 20.475C16.25 20.035 15.9025 19.6875 15.475 19.6875H14.525C13.0638 19.6875 11.875 18.4987 11.875 17.0375C11.875 15.7238 12.8225 14.6425 14.0625 14.4225V13.4375C14.0625 12.92 14.4825 12.5 15 12.5C15.5175 12.5 15.9375 12.92 15.9375 13.4375V14.375H17.1875C17.705 14.375 18.125 14.795 18.125 15.3125C18.125 15.83 17.705 16.25 17.1875 16.25H14.525C14.0975 16.25 13.75 16.5975 13.75 17.025C13.75 17.465 14.0975 17.8125 14.525 17.8125Z"
          fill="#FFBB38"
        />
      </g>
      <defs>
        <clipPath id="clip0_4433_314">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const AllocatedBudgetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
    >
      <path
        d="M27.5 4.25H21.25V2.99996C21.25 1.62119 20.1288 0.499937 18.75 0.499937H11.25C9.87123 0.499937 8.74998 1.62113 8.74998 2.99996V4.24994H2.50002C1.12119 4.25 0 5.37119 0 6.74996V10.5C0 11.8788 1.12119 13 2.50002 13H12.5V12.375C12.5 12.0295 12.7796 11.75 13.1251 11.75H16.8751C17.2205 11.75 17.5001 12.0295 17.5001 12.375V13H27.5001C28.8788 13 30 11.8788 30 10.5V6.74996C30 5.37119 28.8788 4.25 27.5 4.25ZM18.75 4.25H11.25V2.99996H18.75V4.25Z"
        fill="#16DBCC"
      />
      <path
        d="M29.6533 13.6744C29.4403 13.5688 29.1858 13.5932 28.9978 13.736C28.5534 14.0723 28.0358 14.2499 27.4999 14.2499H17.5V16.1249C17.5 16.4704 17.2205 16.75 16.875 16.75H13.125C12.7795 16.75 12.5 16.4704 12.5 16.1249V14.2499H2.50002C1.96412 14.2499 1.44656 14.0723 1.00219 13.736C0.813574 13.592 0.559687 13.5676 0.346641 13.6744C0.134297 13.78 0 13.9966 0 14.2341V23C0 24.3788 1.12119 25.5 2.50002 25.5H27.5C28.8788 25.5 30.0001 24.3788 30.0001 23V14.2341C30 13.9966 29.8657 13.78 29.6533 13.6744Z"
        fill="#16DBCC"
      />
    </svg>
  )
}

export const AvailableBalanceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M17.6871 14.843C21.7793 14.843 25.1081 11.5137 25.1081 7.42151C25.1081 3.32931 21.7793 0 17.6871 0C13.5949 0 10.2656 3.32931 10.2656 7.42151C10.2656 11.5137 13.5949 14.843 17.6871 14.843ZM16.8082 3.05919V2.85763C16.8082 2.37188 17.2014 1.97872 17.6871 1.97872C18.1723 1.97872 18.566 2.37188 18.566 2.85763V3.06036C19.6119 3.28478 20.3989 4.21642 20.3989 5.32853C20.3989 5.81369 20.0051 6.20744 19.52 6.20744C19.0342 6.20744 18.641 5.81369 18.641 5.32853C18.641 5.01857 18.3885 4.76603 18.0785 4.76603H17.2905C16.9805 4.76603 16.728 5.01857 16.728 5.32853C16.728 5.50607 16.8129 5.67482 16.9559 5.78029L17.6871 6.3217L19.4637 7.63655C20.0473 8.06838 20.3989 8.75921 20.4035 9.48577V9.49397C20.4076 10.1139 20.1703 10.6987 19.7344 11.1399C19.411 11.4674 19.0067 11.6871 18.566 11.7797V11.9854C18.566 12.4711 18.1723 12.8643 17.6871 12.8643C17.2014 12.8643 16.8082 12.4711 16.8082 11.9854V11.7827C16.3793 11.6912 15.985 11.4803 15.6662 11.1651C15.225 10.7297 14.9801 10.1485 14.9766 9.52855C14.9736 9.04339 15.3645 8.64729 15.8496 8.64436H15.8555C16.3383 8.64436 16.7315 9.0346 16.7344 9.518C16.7356 9.82134 16.9796 10.077 17.2998 10.077C17.7855 10.0741 17.6046 10.0751 18.0867 10.0723C18.3987 10.0698 18.6481 9.81665 18.6457 9.50569V9.49749C18.6446 9.32171 18.5596 9.15413 18.4178 9.04925L17.6871 8.50843L15.91 7.19358C15.3217 6.75764 14.9701 6.06037 14.9701 5.32853C14.9701 4.21408 15.7594 3.28185 16.8082 3.05919Z"
        fill="#0071FF"
      />
      <path
        d="M5.66251 19.066C5.43721 18.6754 4.93676 18.5395 4.54377 18.7662L0.409502 21.1528C0.018036 21.3793 -0.116261 21.88 0.109736 22.272L4.33518 29.5905C4.56135 29.9819 5.06174 30.1163 5.45391 29.8903L9.58818 27.5032C9.98024 27.2771 10.114 26.7764 9.88795 26.3844L5.66251 19.066Z"
        fill="#0071FF"
      />
      <path
        d="M29.3515 17.6051C28.9536 17.0526 28.1837 16.9278 27.6318 17.3256C25.9571 18.5321 22.7415 20.8489 22.486 21.0335C22.3683 21.1337 22.2446 21.2245 22.1157 21.3048C21.6101 21.6229 21.0235 21.7928 20.4112 21.7928H16.2012C15.7161 21.7928 15.3223 21.3997 15.3223 20.9139C15.3223 20.4276 15.7167 20.035 16.2012 20.035H20.7036C21.3651 20.035 21.8948 19.4842 21.8673 18.8215C21.8415 18.1928 21.3048 17.7053 20.6755 17.7053H17.2483C17.0163 17.4598 16.762 17.2348 16.4895 17.0338C15.5526 16.343 14.3948 15.9346 13.1415 15.9346C10.9131 15.9346 8.69595 17.3385 7.77661 19.2124L11.3719 25.4391H18.1161C19.4403 25.4391 20.7446 25.1005 21.8925 24.4401C22.2903 24.2116 22.7163 23.9368 23.178 23.6051C25.1128 22.2153 29.0685 19.3278 29.0714 19.326C29.6245 18.9288 29.7499 18.1577 29.3515 17.6051Z"
        fill="#0071FF"
      />
    </svg>
  )
}
export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M13 13.5L10.3333 10.8333M11.6667 6.83333C11.6667 8.24782 11.1048 9.60438 10.1046 10.6046C9.10438 11.6048 7.74782 12.1667 6.33333 12.1667C4.91885 12.1667 3.56229 11.6048 2.5621 10.6046C1.5619 9.60438 1 8.24782 1 6.83333C1 5.41885 1.5619 4.06229 2.5621 3.0621C3.56229 2.0619 4.91885 1.5 6.33333 1.5C7.74782 1.5 9.10438 2.0619 10.1046 3.0621C11.1048 4.06229 11.6667 5.41885 11.6667 6.83333Z"
        stroke="#6B7280"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
export const ErrorIcon = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      style={{ flexShrink: 0 }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="30"
        height="30"
        rx="15"
        transform="matrix(1 0 0 -1 0 30.5)"
        fill="#E90606"
      />
      <path
        d="m11 11.5 8 8m-8 0 8-8"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

//Report Icons

export const CampaignHistoryIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
        fill={fill}
      />
      <path
        d="M15.75 9.75H8.25C7.84 9.75 7.5 9.41 7.5 9C7.5 8.59 7.84 8.25 8.25 8.25H15.75C16.16 8.25 16.5 8.59 16.5 9C16.5 9.41 16.16 9.75 15.75 9.75Z"
        fill={fill}
      />
      <path d="M15.75 15.75H8.25C7.84 15.75 7.5 15.41 7.5 15C7.5 14.59 7.84 14.25 8.25 14.25H15.75C16.16 14.25 16.5 14.59 16.5 15C16.5 15.41 16.16 15.75 15.75 15.75Z" />
    </svg>
  )
}

export const ArrowIconCarousel = ({ fill = "#0071ff" }: { fill?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" />
      <path
        d="M15.0003 20.6695C14.8103 20.6695 14.6203 20.5995 14.4703 20.4495L7.95027 13.9295C6.89027 12.8695 6.89027 11.1295 7.95027 10.0695L14.4703 3.54953C14.7603 3.25953 15.2403 3.25953 15.5303 3.54953C15.8203 3.83953 15.8203 4.31953 15.5303 4.60953L9.01027 11.1295C8.53027 11.6095 8.53027 12.3895 9.01027 12.8695L15.5303 19.3895C15.8203 19.6795 15.8203 20.1595 15.5303 20.4495C15.3803 20.5895 15.1903 20.6695 15.0003 20.6695Z"
        fill={fill}
      />
    </svg>
  )
}

export const DeliveryStatusIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 18.95C9.38 18.95 7.25 16.82 7.25 14.2C7.25 11.58 9.38 9.45 12 9.45C14.62 9.45 16.75 11.58 16.75 14.2C16.75 16.82 14.62 18.95 12 18.95ZM12 10.95C10.21 10.95 8.75 12.41 8.75 14.2C8.75 15.99 10.21 17.45 12 17.45C13.79 17.45 15.25 15.99 15.25 14.2C15.25 12.41 13.79 10.95 12 10.95Z"
        fill={fill}
      />
      <path
        d="M11.44 15.83C11.12 15.83 10.8 15.71 10.56 15.47L9.91 14.82C9.62 14.53 9.62 14.05 9.91 13.76C10.2 13.47 10.68 13.47 10.97 13.76L11.45 14.24L13.06 12.76C13.36 12.48 13.84 12.5 14.12 12.8C14.4 13.1 14.38 13.58 14.08 13.86L12.3 15.5C12.05 15.72 11.75 15.83 11.44 15.83Z"
        fill={fill}
      />
      <path
        d="M16 22.75H7.99998C3.37998 22.75 2.51998 20.6 2.29998 18.51L1.54998 10.5C1.43998 9.44999 1.40998 7.89999 2.44998 6.73999C3.34998 5.73999 4.83998 5.25999 6.99998 5.25999H17C19.17 5.25999 20.66 5.74999 21.55 6.73999C22.59 7.89999 22.56 9.44999 22.45 10.51L21.7 18.5C21.48 20.6 20.62 22.75 16 22.75ZM6.99998 6.74999C5.30998 6.74999 4.14998 7.07999 3.55998 7.73999C3.06998 8.27999 2.90998 9.10999 3.03998 10.35L3.78998 18.36C3.95998 19.94 4.38998 21.25 7.99998 21.25H16C19.6 21.25 20.04 19.94 20.21 18.35L20.96 10.36C21.09 9.10999 20.93 8.27999 20.44 7.73999C19.85 7.07999 18.69 6.74999 17 6.74999H6.99998Z"
        fill={fill}
      />
      <path
        d="M16 6.75C15.59 6.75 15.25 6.41 15.25 6V5.2C15.25 3.42 15.25 2.75 12.8 2.75H11.2C8.75 2.75 8.75 3.42 8.75 5.2V6C8.75 6.41 8.41 6.75 8 6.75C7.59 6.75 7.25 6.41 7.25 6V5.2C7.25 3.44 7.25 1.25 11.2 1.25H12.8C16.75 1.25 16.75 3.44 16.75 5.2V6C16.75 6.41 16.41 6.75 16 6.75Z"
        fill={fill}
      />
      <path
        d="M16.01 14.39C15.67 14.39 15.37 14.16 15.28 13.82C15.18 13.42 15.42 13.01 15.82 12.91C17.77 12.42 19.58 11.57 21.2 10.39C21.53 10.15 22 10.22 22.25 10.56C22.49 10.89 22.42 11.36 22.08 11.61C20.3 12.9 18.32 13.83 16.18 14.37C16.13 14.38 16.07 14.39 16.01 14.39Z"
        fill={fill}
      />
      <path
        d="M7.99999 14.42C7.93999 14.42 7.87999 14.41 7.81999 14.4C5.80999 13.91 3.91999 13.06 2.18999 11.88C1.84999 11.65 1.75999 11.18 1.98999 10.84C2.21999 10.5 2.68999 10.41 3.02999 10.64C4.60999 11.72 6.32999 12.49 8.16999 12.94C8.56999 13.04 8.81999 13.44 8.71999 13.85C8.64999 14.19 8.33999 14.42 7.99999 14.42Z"
        fill={fill}
      />
    </svg>
  )
}

export const PaymentHistoryIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.74 22.75H7.64001C7.33001 22.75 7.05002 22.56 6.94002 22.26C6.83002 21.96 6.91001 21.64 7.15001 21.43C7.85001 20.81 8.26001 19.92 8.26001 18.99C8.26001 17.2 6.80001 15.74 5.01001 15.74C4.27001 15.74 3.57001 15.99 2.98001 16.46C2.75001 16.64 2.45002 16.67 2.19002 16.55C1.93002 16.43 1.77002 16.16 1.77002 15.87V11.5C1.77002 9.01 3.79001 6.98999 6.28001 6.98999H17.76C20.25 6.98999 22.27 9.01 22.27 11.5V12.94C22.27 13.35 21.93 13.69 21.52 13.69H19.5C19.15 13.69 18.83 13.82 18.6 14.06L18.59 14.07C18.31 14.34 18.18 14.71 18.21 15.09C18.27 15.75 18.9 16.28 19.62 16.28H21.52C21.93 16.28 22.27 16.62 22.27 17.03V18.22C22.25 20.73 20.23 22.75 17.74 22.75ZM9.18001 21.25H17.74C19.4 21.25 20.75 19.9 20.75 18.24V17.8H19.6C18.09 17.8 16.81 16.68 16.69 15.24C16.61 14.42 16.91 13.61 17.51 13.02C18.03 12.49 18.73 12.2 19.48 12.2H20.75V11.51C20.75 9.85001 19.4 8.5 17.74 8.5H6.26001C4.60001 8.5 3.25002 9.85001 3.25002 11.51V14.59C3.81002 14.37 4.40002 14.25 5.00002 14.25C7.62002 14.25 9.75002 16.38 9.75002 19C9.75002 19.79 9.55001 20.57 9.18001 21.25Z"
        fill={fill}
      />
      <path
        d="M2.5 12.2601C2.09 12.2601 1.75 11.9201 1.75 11.5101V7.84011C1.75 6.35011 2.68001 4.99005 4.07001 4.47005L12.01 1.47005C12.83 1.17005 13.75 1.28011 14.46 1.78011C15.17 2.28011 15.6 3.09009 15.6 3.95009V7.75008C15.6 8.16008 15.26 8.50008 14.85 8.50008C14.44 8.50008 14.1 8.16008 14.1 7.75008V3.95009C14.1 3.57009 13.92 3.23009 13.61 3.01009C13.29 2.79009 12.9 2.74008 12.54 2.87008L4.60001 5.87008C3.78001 6.18008 3.25 6.95011 3.25 7.84011V11.5101C3.25 11.9201 2.91 12.2601 2.5 12.2601Z"
        fill={fill}
      />
      <path
        d="M19.6 17.7999C18.09 17.7999 16.81 16.6799 16.69 15.2399C16.61 14.4099 16.91 13.5999 17.51 13.0099C18.02 12.4899 18.72 12.2 19.47 12.2H21.55C22.54 12.23 23.3 13.0099 23.3 13.9699V16.03C23.3 16.99 22.54 17.7699 21.58 17.7999H19.6ZM21.53 13.7H19.48C19.13 13.7 18.81 13.8299 18.58 14.0699C18.29 14.3499 18.15 14.7299 18.19 15.1099C18.25 15.7699 18.88 16.2999 19.6 16.2999H21.56C21.69 16.2999 21.81 16.18 21.81 16.03V13.9699C21.81 13.8199 21.69 13.71 21.53 13.7Z"
        fill={fill}
      />
      <path
        d="M14 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z"
        fill={fill}
      />
      <path
        d="M5 23.75C2.38 23.75 0.25 21.62 0.25 19C0.25 17.54 0.899999 16.19 2.03 15.29C2.87 14.62 3.93 14.25 5 14.25C7.62 14.25 9.75 16.38 9.75 19C9.75 20.36 9.16 21.66 8.14 22.56C7.26 23.33 6.15 23.75 5 23.75ZM5 15.75C4.26 15.75 3.56 16 2.97 16.47C2.2 17.08 1.75 18.01 1.75 19C1.75 20.79 3.21 22.25 5 22.25C5.78 22.25 6.53999 21.96 7.14999 21.44C7.84999 20.82 8.25 19.94 8.25 19C8.25 17.21 6.79 15.75 5 15.75Z"
        fill={fill}
      />
      <path
        d="M3.99988 20.75C3.74988 20.75 3.49988 20.62 3.35988 20.39C3.14988 20.03 3.25988 19.57 3.61988 19.36L4.50988 18.83V17.75C4.50988 17.34 4.84988 17 5.25988 17C5.66988 17 6.00988 17.34 6.00988 17.75V19.25C6.00988 19.51 5.86988 19.76 5.64988 19.89L4.39988 20.64C4.26988 20.72 4.12988 20.75 3.99988 20.75Z"
        fill={fill}
      />
    </svg>
  )
}

export const RedemptionHistoryIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.42 11.75H16C15.59 11.75 15.25 11.41 15.25 11V4.01C15.25 3.27 15.54 2.58 16.06 2.06C16.58 1.54 17.27 1.25 18.01 1.25H18.02C19.27 1.26 20.45 1.75 21.35 2.64C22.25 3.55 22.74 4.75 22.74 6V8.42C22.75 10.41 21.41 11.75 19.42 11.75ZM16.75 10.25H19.42C20.58 10.25 21.25 9.58 21.25 8.42V6C21.25 5.14 20.91 4.32 20.3 3.7C19.69 3.1 18.88 2.76 18.02 2.75C18.02 2.75 18.02 2.75 18.01 2.75C17.68 2.75 17.36 2.88 17.12 3.12C16.88 3.36 16.75 3.67 16.75 4.01V10.25Z"
        fill={fill}
      />
      <path
        d="M8.99976 23.33C8.52976 23.33 8.08977 23.15 7.75977 22.81L6.09973 21.14C6.00973 21.05 5.86978 21.04 5.76978 21.12L4.05975 22.4C3.52975 22.8 2.82974 22.87 2.22974 22.57C1.62974 22.27 1.25977 21.67 1.25977 21V6C1.25977 2.98 2.98977 1.25 6.00977 1.25H18.0098C18.4198 1.25 18.7598 1.59 18.7598 2C18.7598 2.41 18.4198 2.75 18.0098 2.75C17.3198 2.75 16.7598 3.31 16.7598 4V21C16.7598 21.67 16.3897 22.27 15.7897 22.57C15.1997 22.87 14.4898 22.8 13.9598 22.4L12.2498 21.12C12.1498 21.04 12.0097 21.06 11.9297 21.14L10.2498 22.82C9.90976 23.15 9.46976 23.33 8.99976 23.33ZM5.90973 19.57C6.36973 19.57 6.81973 19.74 7.15973 20.09L8.81976 21.76C8.87976 21.82 8.95976 21.83 8.99976 21.83C9.03976 21.83 9.11975 21.82 9.17975 21.76L10.8597 20.08C11.4797 19.46 12.4598 19.4 13.1498 19.93L14.8497 21.2C14.9597 21.28 15.0597 21.25 15.1097 21.22C15.1597 21.19 15.2498 21.13 15.2498 21V4C15.2498 3.55 15.3597 3.12 15.5497 2.75H5.99976C3.77976 2.75 2.74976 3.78 2.74976 6V21C2.74976 21.14 2.83977 21.2 2.88977 21.23C2.94977 21.26 3.04978 21.28 3.14978 21.2L4.85974 19.92C5.16974 19.69 5.53973 19.57 5.90973 19.57Z"
        fill={fill}
      />
      <path
        d="M12 9.75H6C5.59 9.75 5.25 9.41 5.25 9C5.25 8.59 5.59 8.25 6 8.25H12C12.41 8.25 12.75 8.59 12.75 9C12.75 9.41 12.41 9.75 12 9.75Z"
        fill={fill}
      />
      <path
        d="M11.25 13.75H6.75C6.34 13.75 6 13.41 6 13C6 12.59 6.34 12.25 6.75 12.25H11.25C11.66 12.25 12 12.59 12 13C12 13.41 11.66 13.75 11.25 13.75Z"
        fill={fill}
      />
    </svg>
  )
}

export const DeleteIconUpdated = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.7234 14.6667C7.82007 14.6667 6.9394 14.6567 6.06807 14.6387C4.9534 14.6167 4.18207 13.894 4.05607 12.7527C3.84607 10.8593 3.48673 6.39666 3.4834 6.352C3.46073 6.07666 3.66607 5.83533 3.9414 5.81333C4.21273 5.806 4.45807 5.99666 4.48007 6.27133C4.4834 6.31666 4.84207 10.764 5.05007 12.6427C5.1214 13.2913 5.4714 13.626 6.08873 13.6387C7.7554 13.674 9.45607 13.676 11.2894 13.6427C11.9454 13.63 12.3001 13.302 12.3734 12.638C12.5801 10.7753 12.9401 6.31666 12.9441 6.27133C12.9661 5.99666 13.2094 5.80466 13.4821 5.81333C13.7574 5.836 13.9627 6.07666 13.9407 6.352C13.9367 6.39733 13.5754 10.8713 13.3674 12.748C13.2381 13.9127 12.4687 14.6213 11.3074 14.6427C10.4187 14.658 9.5614 14.6667 8.7234 14.6667Z"
        fill="#6B7280"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3644 4.65952H3.05908C2.78308 4.65952 2.55908 4.43552 2.55908 4.15952C2.55908 3.88352 2.78308 3.65952 3.05908 3.65952H14.3644C14.6404 3.65952 14.8644 3.88352 14.8644 4.15952C14.8644 4.43552 14.6404 4.65952 14.3644 4.65952Z"
        fill="#6B7280"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.186 4.65953C11.4273 4.65953 10.7686 4.11886 10.6193 3.37486L10.4573 2.56419C10.4233 2.44086 10.2826 2.33353 10.1226 2.33353H7.30064C7.14064 2.33353 6.99997 2.44086 6.9593 2.59486L6.80397 3.37486C6.6553 4.11886 5.99597 4.65953 5.2373 4.65953C4.9613 4.65953 4.7373 4.43553 4.7373 4.15953C4.7373 3.88353 4.9613 3.65953 5.2373 3.65953C5.5213 3.65953 5.76797 3.45686 5.82397 3.17819L5.98597 2.36753C6.15064 1.74619 6.68864 1.33353 7.30064 1.33353H10.1226C10.7346 1.33353 11.2726 1.74619 11.4306 2.33753L11.6 3.17819C11.6553 3.45686 11.902 3.65953 12.186 3.65953C12.462 3.65953 12.686 3.88353 12.686 4.15953C12.686 4.43553 12.462 4.65953 12.186 4.65953Z"
        fill="#6B7280"
      />
    </svg>
  )
}
