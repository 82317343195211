import theme from "./theme"
import type { FCC } from "@interfaces/common.interface"
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material"
import { enUS, arSA } from "@mui/material/locale"
import { CacheProvider } from "@emotion/react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import createCache from "@emotion/cache"
import { prefixer } from "stylis"
import rtlPlugin from "stylis-plugin-rtl"

const AppThemeProvider: FCC = ({ children }) => {
  // here we override default styles

  const {
    i18n: { language },
  } = useTranslation()

  const appTheme = useMemo(
    () =>
      createTheme(
        {
          ...theme,
          components: {
            MuiFormLabel: {
              styleOverrides: {
                root: {
                  color: theme.palette.secondary.main,
                },
              },
            },
            MuiButton: {
              styleOverrides: {
                root: {
                  boxShadow: "none",
                  borderRadius: "6px",
                  height: "38px",
                  "&.Mui-disabled": {
                    opacity: 0.2,
                    backgroundColor: theme.palette.primary.main,
                    color: "#fff",
                  },
                },
              },
            },
            MuiTooltip: {
              styleOverrides: {
                tooltip: {
                  backgroundColor: "#1f1f1f",
                  color: "#fff",
                  borderRadius: "4px",
                },
              },
            },
          },
          typography: {
            fontFamily:
              language === "en" ? `"Inter",sans-serif` : ` "Cairo", sans-serif`,
            button: {
              textTransform: "none",
            },
            h4: {
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: 1,
            },
          },
          direction: language === "en" ? "ltr" : "rtl",
        },
        language === "en" ? enUS : arSA,
      ),
    [language],
  )

  // Create rtl cache
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  })

  const emptyCache = createCache({
    key: "meaningless-key",
  })

  return (
    <CacheProvider value={language === "ar" ? cacheRtl : emptyCache}>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>{children}</ThemeProvider>
    </CacheProvider>
  )
}

export default AppThemeProvider
